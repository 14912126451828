<template>
    <div class="hexagon">
        <div class="hexagon__header">
            <h1>Hexagon</h1>
            <ph-info v-if="!showDescription" :size="28" class="hexagon__header--icon hexagon__icon" @click="showDescription = true" />
        </div>
        <div class="hexagon__description" :class="{'hexagon__description--show': showDescription}" >
            <div class="hexagon__icon-container">
                <ph-x :size="28" class="hexagon__icon" @click="showDescription = false" />
            </div>
            <li>This game is from Korean reality show called <a href="https://www.netflix.com/title/81653386" target="__blank">The Devil's Plan</a>.</li>
            <li>There are 19 random numbers from 1 to 9, and after 60 seconds, the numbers will be replaced with an alphabet character in order.</li>
            <li>You will be given a target number, and you must remember which 3 combinations of numbers will make the target number.</li>
            <li>
                You can select and deselect the number, and once 3 numbers are selected, 
                you will gain 1 point if it is correct, but you will lose 1 point if it is incorrect.
            </li>
            <li><b>You can only select 3 numbers that make a straight line (ex. ABC, ADH, AEJ).</b></li>
        </div>
        <div class="hex-numbers">
            <div class="hex-numbers__container">
                <div>Target Number</div>
                <div class="hex-numbers__display">{{ numbersSeen ? target : '?' }}</div>
            </div>
            <div class="hex-numbers__container">
                <div>Possible Answers</div>
                <div class="hex-numbers__display">{{ numbersSeen ? numPossibleAnswer : '?' }}</div>
            </div>
            <div class="hex-numbers__container">
                <div>Earned Points</div>
                <div class="hex-numbers__display" :class="{'hex-numbers__display--warning' :points < 0}">{{ points }}</div>
            </div>
        </div>
        <div class="hex-container">
            <div v-for="(row, i) in hexagons" :key="i" class="hex-row">
               <div
                    v-for="cell in row"
                    :key="cell.char"
                    class="hex"
                    :class="{'hex__selected': isSelected(cell), 'hex__disable': showNumbers}"
                    @click="showNumbers ? null : onSelect(cell)"
                >
                    <div class="hex__top"></div>
                    <div class="hex__middle">{{ showNumbers ? cell.num : cell.char }}</div>
                    <div class="hex__bottom"></div>
               </div> 
            </div>
        </div>
        <div class="hexagon__footer">
            <p>{{ message }}</p>
            <p v-if="time" class="hexagon__time" :class="{'hexagon__time--warning' : time < 11}">{{ time }}</p>
            <div v-if="numbersSeen" class="hexagon__footer-buttons">
                <div class="hexagon__next-game" @click="nextGame">Next Game</div>
                <div class="hexagon__reset" @click="reset">Reset (0 points)</div>
            </div>
            <div class="hex-numbers">
                <div class="hex-numbers__container">
                    <div>Set Time (seconds)</div>
                    <input class="hex-numbers__number" type="text" v-model="timeInput" />
                </div>
                <div class="hex-numbers__container">
                    <div>Set Range (max num of cell)</div>
                    <input class="hex-numbers__number" type="text" v-model="rangeInput" />
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { PhInfo } from 'phosphor-vue';

const ALPHABET = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
const MESSAGES = {
    'start': 'Click any cell to start the game!',
    'memorize': 'Memorize the numbers!',
    'playing': 'Click 3 cells in a row that makes the target number.',
};
export default {
    name: 'hexagon-game',
    components: {
        PhInfo,
    },
    data() {
        return {
            showDescription: false,
            showNumbers: false,
            numbersSeen: false,
            target: -1,
            points: 0,
            time: 0,
            timeInput: 60,
            size: 3,
            rangeInput: 7,
            hexagons: [],
            selected: [],
            message: MESSAGES['start'],
            candidates: {},
            numPossibleAnswer: undefined,
        };
    },
    computed: {
        fullSelected() {
            return this.selected.length === 3;
        },
    },
    methods: {
        generateNumbers() {
            /**
             * 2*sum(1=0:n-2, n+1) + (2n-1)
             * 2*(n(n-1) + sum(i=0:n-2, i) + 2n-1
             * sum(i=1:n, i) = n(n+1)/2
             * 2n(n-1) + 2(n-1)(n-2)/2 + 2n - 1
             * 2n(n-1) + (n-1)(n-2) + 2n - 1
             * (n-1)(3n-2) - 2n - 1
             */
            let n = this.size;
            let totalHexSize = (n - 1)*(3 * n - 2) + (2 * n) - 1;
            let tempHex = Array.from({length: totalHexSize}, () => Math.floor(Math.random() * this.rangeInput) + 1).map((num, i) => {
                return { char: ALPHABET[i], num: num };
            });
            
            /**
             * Scalable Portion
             * Hexagon Array Length = 2n - 1 (n = # of cell on 1 side of hexagon)
             * length = (i < n) ? n + i : n + (2 * n - 2 - 1);
             */
            this.hexagons = [];
            let numHexArray = 2 * n - 1;
            let j = 0;
            for (let i = 0; i < numHexArray; i++) {
                let idx = (i < n) ? n + i : n + (2 * n - 2 - i);
                this.hexagons.push(tempHex.slice(j, j + idx));
                j += idx;
            }

            this.generateCandidates();
        },
        generateCandidates() {
            this.candidates = {};
            let k = 3;
            let row0, row1, row2, rowsExist;
            let longest = this.size - 1;

            for (let i = 0; i < this.hexagons.length; i++) {
                row0 = this.hexagons[i];
                row1 = this.hexagons[i + 1];
                row2 = this.hexagons[i + 2];
                rowsExist = row0 && row1 && row2;

                for (let j = 0; j < row0.length; j++) {
                    // straigt lines
                    this.saveCandidate(...row0.slice(j, j+k));
                }

                for (let j = 0; j < row0.length; j++) {
                    let q1i0, q1i1, q1i2; // quadrant 1 (/ this diagonal)
                    let q2i0, q2i1, q2i2; // quadrant 2 (\ this diagonal)
                    
                    if (rowsExist) {
                        if (i === longest) {
                            [q1i0, q1i1, q1i2] = [j, j - 1, j - 2];
                            [q2i0, q2i1, q2i2] = [j, j, j];
                        } else if (i === longest - 1) {
                            [q1i0, q1i1, q1i2] = [j, j, j - 1];
                            [q2i0, q2i1, q2i2] = [j, j + 1, j + 1];
                        } else if (row0[j] && row1[j] && row2[j]) {
                            [q1i0, q1i1, q1i2] = [j, j, j];
                            [q2i0, q2i1, q2i2] = [j, j + 1, j + 2];
                        }

                        this.saveCandidate(row0[q1i0], row1[q1i1], row2[q1i2]);
                        this.saveCandidate(row0[q2i0], row1[q2i1], row2[q2i2]);
                    }
                }
            }
            this.generateTarget();
        },
        saveCandidate(x, y, z) {
            if (x && y && z) {
                let candidate = x.char + y.char + z.char;
                let sum = x.num + y.num + z.num;
                this.candidates[candidate] = sum;
            }
        },
        generateTarget() {
            let targets = {};
            let mostFrequent = 0;

            for (let item in this.candidates) {
                let num = this.candidates[item];
                if (!targets[num]) {
                    targets[num] = 0;
                }
                targets[num] += 1;

                if (targets[num] > mostFrequent) {
                    mostFrequent = targets[num];
                    this.target = num;
                }
            }
            this.numPossibleAnswer = targets[this.target];
        },
        onSelect(cell) {
            if (!this.showNumbers && !this.numbersSeen) {
                this.showNumbers = true;
                this.message = MESSAGES['memorize'];
                this.time = this.timeInput;

                let timer = setInterval(() => {
                    this.time--;
                    if (this.time === 0) {
                        this.showNumbers = false;
                        this.message = MESSAGES['playing'];
                        this.numbersSeen = true;
                        clearInterval(timer);
                    }
                }, 1000);
            } else if (this.isSelected(cell)) {
                this.selected = this.selected.filter(val => val !== cell);
            } else if (!this.fullSelected) {
                this.selected.push(cell);
            }

            if (this.fullSelected) {
                this.checkAnswer();
            }
        },
        isSelected(cell) {
            return this.selected.includes(cell);
        },
        checkAnswer() {
            let answer = '';
            let sumNum = 0;

            this.selected.forEach(cell => {
                answer += cell.char;
                sumNum += cell.num;
            });
            answer = answer.split('').sort().join('');

            let isCorrect = sumNum === this.target;
            let isIncluded =  Object.keys(this.candidates).includes(answer);

            this.points += (isCorrect && isIncluded) ? 1 : -1;
            
            this.selected = [];
            if (isIncluded) {
                delete this.candidates[answer];
            }
        },
        reset() {
            this.points = 0;
            this.nextGame();
        },
        nextGame() {
            this.target = -1;
            this.selected = [];
            this.numbersSeen = false;
            this.message = MESSAGES['start'];
            this.generateNumbers();
        },
    },
    created() {
        this.generateNumbers();
    },
}
</script>

<style lang="scss">
@import './hexagon.scss';
</style>