<template>
    <div class="about">
        <div class="about__content">
            <div class="pre-header">
                <ph-hand-waving :size="22" weight="light" class="pre-header__icon" />
                <p>Hello, nice to meet you! :)</p>
            </div>
            <h1>About me</h1>
            <div class="about__paragraph">
                <p>
                    I am Heeyeon Lee, a Front-end Engineer with 3 years of industry experience.
                    I graduated with a Computer Science degree from Brigham Young University, Provo, Utah.
                </p>
                <p>
                    Currently, I am employed at Awardco, an employee recognition software company, where I have had the opportunity to contribute to the development of a dynamic homepage editor and self-editable email template services. These innovations empower users to customize their experience with our platform. I have a passion for building scalable and reusable components.
                </p>
                <p>
                    As a Front-end Engineer, my primary goal is to create better software that is user-friendly, accessible, scalable, and aesthetically appealing.
                </p>
            </div>
            <div class="about__img">
                <img class="about__img--content" src="../../src/assets/profile.jpg" />
            </div>
            <div class="about__connect">
                <p>Let's connect!</p>
                <a target="_blank" href="https://www.linkedin.com/in/heeyeonl/">
                    <ph-linkedin-logo :size="32" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { PhHandWaving, PhLinkedinLogo } from "phosphor-vue";

export default {
    name: 'about-me',
    components: {
        PhHandWaving,
        PhLinkedinLogo,
    },
}
</script>

<style lang="scss">
.about {
    display: flex;
    justify-content: center;

    &__content {
        max-width: 500px;
    }
    &__paragraph {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        margin-bottom: 36px;
    }
    &__img {
        text-align: center;
        &--content{
            width: 300px;
            height: 300px;
            border-radius: 50%;
        }
    }
    &__connect {
        text-align: center;
        margin: 24px 0;
        a {
            color: var(--primary-color);
            @media (hover: hover) {
                &:hover {
                    color: var(--primary-color-hover);
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .about {
        &__img {
            &--content {
                width: 200px;
                height: 200px;
            }
        }
    }
}

</style>