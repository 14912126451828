<template>
    <div class="welcome">
        <div class="welcome__message">
            Hi I'm 
            <div>
                {{ index === 0 ? '' : 'a'}}
                <span :class="{'welcome__message-under': index !== 0}">
                    <span class="welcome__message--fade">
                        {{ words[index] }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'welcome-page',
    data() {
        return {
            index: 0,
            words: [
                'Heeyeon',
                'developer',
                'knitter',
                'gamer',
                'cat lover',
            ],
        };
    },
    created() {
        window.setInterval(() => {
            if (this.index < this.words.length - 1) {
                this.index++;
            } else {
                this.index = 0;
            }
        }, 5000);
    }
}
</script>

<style lang="scss">
.welcome {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    
    &__message {
        display: flex;
        column-gap: 20px;
        width: 570px;
        font-family: 'Sansita One', sans-serif;
        color: var(--ui-black);
        font-size: 60px;
        letter-spacing: 1px;

        &--fade {
            animation: fade 5s infinite;
        }
    }

    &__message-under {
        width: 320px;
        border-bottom: solid 2px black;
        display: inline-block;
        text-align: center;
    }
}

@media screen and (max-width: 768px) {
    .welcome {
        &__message {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 40px;
        }
        &__message-under {
            width: 200px;
        }
    }
}
</style>