<template>
  <div class="app-container">
    <!-- Logo -->
    <div class="logo" @click="onClickLogo">
      <img class="logo__img" src="../src/assets/logo.png" />
    </div>
    <!-- Nav Bar -->
    <div class="nav-button" :class="{'nav-button__open': showNav}" @click="onClickNav">
      <ph-list v-if="!showNav" :size="28" />
      <ph-x v-else :size="28" />
    </div>
    <div id="nav" class="nav" :class="{'nav__show': showNav}">
      <router-link to="/" class="nav__list" @click="closeNav">Home</router-link>
      <div class="dropdown__button nav__list" @click="onClickDropdown">
        <span>Games</span>
        <ph-caret-down v-if="!showDropdown" :size="28" />
        <ph-caret-up v-else :size="28" />
      </div>
      <div class="dropdown" :class="{'dropdown__show': showDropdown}">
        <router-link to="/games/hexagon" class="nav__list" @click="closeNav">Hexagon</router-link>
      </div>
      <!-- <router-link to="/knitting" class="nav__list" @click="closeNav">Knitting</router-link> -->
      <router-link to="/about" class="nav__list" @click="closeNav">About</router-link>
    </div>
    <!-- Router View -->
    <router-view :class="{'router-view': $route.name !== 'WelcomePage' }" @click="closeNav"/>
  </div>
</template>

<script>
import { PhCaretDown, PhCaretUp, PhList, PhX } from "phosphor-vue";

export default {
    name: 'App',
    components: {
      PhCaretDown,
      PhCaretUp,
      PhList,
      PhX,
    },
    data() {
      return {
        showNav: false,
        showDropdown: false,
      };
    },
    methods: {
      onClickLogo() {
        window.location = "/";
      },
      onClickNav() {
        this.showNav = !this.showNav;
        this.showDropdown = false;
      },
      closeNav() {
        this.showNav = false;
        this.showDropdown = false;
      },
      onClickDropdown() {
        this.showDropdown = !this.showDropdown;
      },
    },
}
</script>


<style lang="scss">
@import "@/style/general.scss";
@import "@/style/app.scss";
@import "@/style/animations.scss";
</style>