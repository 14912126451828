import { createRouter, createWebHistory } from 'vue-router'
import WelcomePage from '@/views/WelcomePage.vue';
import HexagonGame from '@/views/games/HexagonGame.vue';
import KnittingPage from '@/views/KnittingPage.vue';
import AboutMe from '@/views/AboutMe.vue';

const routes = [
    { 
        path: '/',
        name: 'WelcomePage',
        component: WelcomePage,
    },
    {
        path: '/games/hexagon',
        name: 'HexagonGame',
        component: HexagonGame,
    },
    {
        path: '/knitting',
        name: 'KnittingPage',
        component: KnittingPage,
    },
    {
        path: '/about',
        name: 'About',
        component: AboutMe,
    },

];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;