<template>
    <div>
        <h1>Knitting</h1>
        <div>
            <label class="switch">
                <input v-model="isMeter" type="checkbox">
                <span class="slider round" />
            </label>
        </div>
        <div>
            <h2>Yarn Weight Calculator</h2>
            <input v-model="thinYarn" type="number" />
            <input v-model="thickYarn" type="number" />
        </div>
        <div>
            <h2>Yarn Weight Reference</h2>
            <table class="knitting__table">
                <thead class="knitting__table-head">
                    <th>Weight</th>
                    <th>min</th>
                    <th>max</th>
                </thead>
                <tbody class="knitting__table-body">
                    <tr v-for="(yarn, index) in YARN_WEIGHT" :key="index">
                        <td>{{ yarn.name }}</td>
                        <td>{{ isMeter ? yarn.minMeter : yarn.minYard }}</td>
                        <td>{{ isMeter ? yarn.maxMeter : yarn.maxYard }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
const YARN_WEIGHT = [
    { name: 'Laceweight', minMeter: 600, minYard: 656, maxMeter: 800, maxYard: 874 },
    { name: 'Light Fingering', minMeter: 500, minYard: 547, maxMeter: 600, maxYard: 656 },
    { name: '4-ply', minMeter: 400, minYard: 437, maxMeter: 500, maxYard: 547 },
    { name: 'Sport', minMeter: 300, minYard: 328, maxMeter: 400, maxYard: 347 },
    { name: 'DK', minMeter: 240, minYard: 262, maxMeter: 300, maxYard: 328 },
    { name: 'Worsted', minMeter: 200, minYard: 219, maxMeter: 240, maxYard: 262 },
    { name: 'Aran', minMeter: 120, minYard: 131, maxMeter: 200, maxYard: 219 },
    { name: 'Chunky', minMeter: 100, minYard: 109, maxMeter: 120, maxYard: 131 },
];

export default {
    name: 'knitting-page',
    data() {
        return {
            YARN_WEIGHT,
            isMeter: true,
            thinYarn: undefined,
            thickYarn: undefined,
        };
    },
}
</script>

<style lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightgray;
  -webkit-transition: .4s;
  transition: .4s;

    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: var(--ui-white);
        -webkit-transition: .4s;
        transition: .4s;
    }
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    
    &:before {
        border-radius: 50%;
    }
}

.knitting {
    &__table {
        font-family: 'Avenir', sans-serif;
        border-collapse: collapse;
        font-size: 0.9em;
        min-width: 400px;
    }

    &__table-head {
        background-color: var(--secondary-color);
        color: #ffffff;
        text-align: left;
        & > th {
            padding: 12px 15px;
        }
        & > th:first-child {
            border-radius: 8px 0 0 0;
        }
        & > th:last-child {
            border-radius: 0 8px 0 0;
        }
    }

    &__table-body {
        background-color: var(--ui-white);
        & > tr:not(:last-child) {
            border-bottom: 1px solid var(--ui-gray);
        }
        & > tr > td {
            padding: 12px 15px;
        }
        & > tr:last-child {
            & > td:first-child {
                border-radius: 0 0 0 8px;
            }
            & > td:last-child {
                border-radius: 0 0 8px 0;
            }
        }
    }
}
</style>